// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatTable_chatTable__m8P\\+x {
  background-color: #F8F8F8;
  font-family: "Poppins";
  color: #0C0C0C;
  text-align: left;
  width: 100%;
  padding: 0 16px;
  margin-top: 20px;
  border-radius: 15px;
  border: 1px black solid;
}
.chatTable_chatTable__m8P\\+x colgroup col:nth-child(1) {
  width: 20%;
}
.chatTable_chatTable__m8P\\+x colgroup col:nth-child(2) {
  width: 58%;
}
.chatTable_chatTable__m8P\\+x colgroup col:nth-child(3) {
  width: 20%;
}
.chatTable_chatTable__m8P\\+x colgroup col:nth-child(4) {
  width: 7%;
}
.chatTable_chatTable__m8P\\+x thead,
.chatTable_chatTable__m8P\\+x tbody tr:not(:last-child) {
  position: relative;
}
.chatTable_chatTable__m8P\\+x thead::after,
.chatTable_chatTable__m8P\\+x tbody tr:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 93%;
  height: 1px;
  background-color: #716F76;
}
.chatTable_chatTable__m8P\\+x thead {
  font-size: 1.4em;
  font-weight: 700;
  line-height: 114.286%;
}
.chatTable_chatTable__m8P\\+x tbody {
  justify-content: center;
  font-size: 0.75em;
  line-height: 133.333%;
}
.chatTable_chatTable__m8P\\+x tr {
  height: 64px;
}
.chatTable_chatTable__m8P\\+x tr button {
  border: none;
  background: none;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/main/Chat/ChatTable/chatTable.module.scss"],"names":[],"mappings":"AAIA;EACI,yBALO;EAMP,sBAAA;EACA,cANS;EAOT,gBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EAEA,uBAAA;AAJJ;AAMQ;EACI,UAAA;AAJZ;AAOQ;EACI,UAAA;AALZ;AAQQ;EACI,UAAA;AANZ;AASQ;EACI,SAAA;AAPZ;AAWI;;EAGI,kBAAA;AAVR;AAYQ;;EACI,WAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,UAAA;EACA,WAAA;EACA,yBA3CG;AAkCf;AAaI;EACI,gBAAA;EACA,gBAAA;EACA,qBAAA;AAXR;AAcI;EACI,uBAAA;EACA,iBAAA;EACA,qBAAA;AAZR;AAeI;EACI,YAAA;AAbR;AAeQ;EACI,YAAA;EACA,gBAAA;EACA,eAAA;AAbZ","sourcesContent":["$bg-color: #F8F8F8;\n$text-color: #0C0C0C;\n$border-color: #716F76;\n\n.chatTable {\n    background-color: $bg-color;\n    font-family: 'Poppins';\n    color: $text-color;\n    text-align: left;\n    width: 100%;\n    padding: 0 16px;\n    margin-top: 20px;\n    border-radius: 15px;\n\n    border: 1px black solid;\n    colgroup {\n        col:nth-child(1) {\n            width: 20%;\n        }\n\n        col:nth-child(2) {\n            width: 58%;\n        }\n\n        col:nth-child(3) {\n            width: 20%;\n        }\n\n        col:nth-child(4) {\n            width: 7%;\n        }\n    }\n\n    thead,\n    tbody tr:not(:last-child) {\n\n        position: relative;\n\n        &::after {\n            content: \"\";\n            position: absolute;\n            bottom: 0;\n            left: 0;\n            width: 93%;\n            height: 1px;\n            background-color: $border-color;\n        }\n    }\n\n    thead {\n        font-size: 1.4em;\n        font-weight: 700;\n        line-height: 114.286%;\n    }\n\n    tbody {\n        justify-content: center;\n        font-size: 0.75em;\n        line-height: 133.333%;\n    }\n\n    tr {\n        height: 64px;\n\n        button {\n            border: none;\n            background: none;\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatTable": `chatTable_chatTable__m8P+x`
};
export default ___CSS_LOADER_EXPORT___;
