// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat_test__Ucnmf {
  height: 80vh;
  width: 80vw;
  background-color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/main/Chat/chat.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,uBAAA;AACJ","sourcesContent":[".test{\n    height: 80vh;\n    width: 80vw;\n    background-color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"test": `chat_test__Ucnmf`
};
export default ___CSS_LOADER_EXPORT___;
