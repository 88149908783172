// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asideChat_asideChat__MnJg4 {
  display: flex;
  flex-direction: column;
  background-color: #F8F8F8;
  grid-column: 3;
  grid-row: 1/3;
  position: fixed;
  right: 0;
  left: 79%;
  border-radius: 12px 0 0 12px;
  transition: all 0.3s ease-in;
}
.asideChat_asideChat__wrapper__nQk-C {
  display: flex;
  flex-direction: column;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/main/Topics/AsideChat/asideChat.module.scss"],"names":[],"mappings":"AAIA;EACI,aAAA;EACA,sBAAA;EACA,yBAPO;EAQP,cAAA;EACA,aAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;EACA,4BAAA;EACA,4BAAA;AAHJ;AAII;EACI,aAAA;EACA,sBAAA;EACA,WAAA;AAFR","sourcesContent":["$bg-color: #F8F8F8;\r\n$input-color: #FFFFFF;\r\n$aside-btn-bg-color: #0C0C0C;\r\n\r\n.asideChat {\r\n    display: flex;\r\n    flex-direction: column;\r\n    background-color: $bg-color;\r\n    grid-column: 3;\r\n    grid-row: 1 / 3;\r\n    position: fixed;\r\n    right: 0;\r\n    left: 79%;\r\n    border-radius: 12px 0 0 12px;\r\n    transition: all .3s ease-in;\r\n    &__wrapper{\r\n        display: flex;\r\n        flex-direction: column;\r\n        width: 100%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"asideChat": `asideChat_asideChat__MnJg4`,
	"asideChat__wrapper": `asideChat_asideChat__wrapper__nQk-C`
};
export default ___CSS_LOADER_EXPORT___;
