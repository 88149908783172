const data = {
  "title": "курс по программированию",
  "content": [
    {
      "title": "Lesson 1: Introduction to Python",
      "content": [
        {
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Understanding Python Syntax",
        },{
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Variables and Data Types in Python"
        },
        {
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Basic Python Operators"
        },
        {
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Control Flow Statements in Python"
        },
        {
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Introduction to Python Libraries"
        },
        {
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Understanding Python IDEs"
        },
        {
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Python Input and Output"
        },
        {
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Python File Handling"
        },
        {
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Python Exception Handling Basics"
        },
        {
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Python Regular Expressions"
        },
        {
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Python Multithreading & Multiprocessing"
        },
        {
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Python Networking Basics"
        },
        {
          "lesson": "Lesson 1: Introduction to Python",
          "subtopic": "Test"
        },
      ]
    },
    {
      "title": "Lesson 2: Python Python Data Structures",
      "content": [
        {
          "lesson": "Lesson 2: Python Python Data Structures",
          "subtopic": "Lists in Python"
        },
        {
          "lesson": "Lesson 2: Python Python Data Structures",
          "subtopic": "Tuples in Python"
        },
        {
          "lesson": "Lesson 2: Python Python Data Structures",
          "subtopic": "Dictionaries in Python"
        },
        {
          "lesson": "Lesson 2: Python Python Data Structures",
          "subtopic": "Understanding Sets in Python"
        },
        {
          "lesson": "Lesson 2: Python Python Data Structures",
          "subtopic": "Working with Strings in Python"
        },
        {
          "lesson": "Lesson 2: Python Python Data Structures",
          "subtopic": "Data Structures: Stacks and Queues"
        },
        {
          "lesson": "Lesson 2: Python Python Data Structures",
          "subtopic": "Data Structures: Linked Lists"
        },
        {
          "lesson": "Lesson 2: Python Python Data Structures",
          "subtopic": "Data Structures: Trees and Graphs"
        },
      ]
    },
    {
      "title": "Lesson 3: Functions in Python",
      "content": [
        {
          "lesson": "Lesson 3: Functions in Python",
          "subtopic": "Defining and Calling Functions "
        },
        {
          "lesson": "Lesson 3: Functions in Python",
          "subtopic": "Function Arguments and Return Values "
        },
        {
          "lesson": "Lesson 3: Functions in Python",
          "subtopic": "Scope and Lifetime of Variables in Functions "
        },
        {
          "lesson": "Lesson 3: Functions in Python",
          "subtopic": "Recursive Functions in Python "
        },
        {
          "lesson": "Lesson 3: Functions in Python",
          "subtopic": "Lambda Functions in Python "
        },
        {
          "lesson": "Lesson 3: Functions in Python",
          "subtopic": "Using Functions in Game Development "
        },
        {
          "lesson": "Lesson 3: Functions in Python",
          "subtopic": "Debugging Functions in Python "
        },
      ]
    }
    ,
    {
      "title": "Lesson 4: Python Modules and Packages",
      "content": [
        {
          "lesson": "Lesson 4",
          "subtopic": "The subtopic 1"
        }
      ]
    },
    {
      "title": "Lesson 5: Object-Oriented Programming in Python",
      "content": [
        {
          "lesson": "Lesson 5",
          "subtopic": "The subtopic 1"
        },
        {
          "lesson": "Lesson 5",
          "subtopic": "The subtopic 2"
        }]

    },
    {
      "title": "Lesson 6: Error Handling in Python",
      "content": [
        {
          "lesson": "Lesson 6",
          "subtopic": "The subtopic 1"
        },
        {
          "lesson": "Lesson 6",
          "subtopic": "The subtopic 2"
        }]

    },
    {
      "title": "Lesson 7: Building the Rock, Scissors, Paper Game",
      "content": [
        {
          "lesson": "Lesson 7: Building the Rock, Scissors, Paper Game",
          "subtopic": "Game Design and Rules"
        },
        {
          "lesson": "Lesson 7: Building the Rock, Scissors, Paper Game",
          "subtopic": "Implementing the Game Logic"
        },
        {
          "lesson": "Lesson 7: Building the Rock, Scissors, Paper Game",
          "subtopic": "Testing and Debugging the Game"
        },
        {
          "lesson": "Lesson 7: Building the Rock, Scissors, Paper Game",
          "subtopic": "Adding User Interface for the Game"
        },
        {
          "lesson": "Lesson 7: Building the Rock, Scissors, Paper Game",
          "subtopic": "Implementing Multiplayer Functionality"
        },
        {
          "lesson": "Lesson 7: Building the Rock, Scissors, Paper Game",
          "subtopic": "Adding Score Keeping Feature"
        },
        {
          "lesson": "Lesson 7: Building the Rock, Scissors, Paper Game",
          "subtopic": "Implementing Game Variations (Rock, Paper, Scissors, Lizard, Spock)"
        },
        {
          "lesson": "Lesson 7: Building the Rock, Scissors, Paper Game",
          "subtopic": "Adding Game Replay Option"
        },
        {
          "lesson": "Lesson 7: Building the Rock, Scissors, Paper Game",
          "subtopic": "Implementing Cheat Codes"
        },
        {
          "lesson": "Lesson 7: Building the Rock, Scissors, Paper Game",
          "subtopic": "Optimizing the Game Code"
        },
        {
          "lesson": "Lesson 7: Building the Rock, Scissors, Paper Game",
          "subtopic": "Final practical asigment"
        },]

    }
  ]
};

export default data;