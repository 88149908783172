// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.links_header__links__Sg34f ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.links_header__links__Sg34f ul a {
  font-family: "Poppins";
  color: #0C0C0C;
  font-size: 1em;
  font-weight: 500;
  line-height: 150%;
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .links_header__links__Sg34f ul {
    flex-direction: column;
    font-size: 1.5em;
  }
}
@media screen and ((min-width: 320px) and (max-width: 480.98px)), (max-width: 319.98px) {
  .links_header__links__Sg34f ul {
    font-size: 1em;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/shared/Links/links.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AADJ;AAGI;EACI,sBAAA;EACA,cAVK;EAWL,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AADR;;AAKA;EACI;IACI,sBAAA;IACA,gBAAA;EAFN;AACF;AAKA;EAEI;IACI,cAAA;EAJN;AACF","sourcesContent":["$text-color: #0C0C0C;\n\n.header__links ul {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 40px;\n\n    a {\n        font-family: 'Poppins';\n        color: $text-color;\n        font-size: 1em;\n        font-weight: 500;\n        line-height: 150%;    \n        text-decoration: none;\n    }\n}\n\n@media (max-width: 991.98px) {\n    .header__links ul {\n        flex-direction: column;\n        font-size: 1.5em;\n    }\n}\n\n@media screen and ((min-width: 320px) and (max-width: 480.98px)),\n(max-width: 319.98px) {\n    .header__links ul {\n        font-size: 1em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header__links": `links_header__links__Sg34f`
};
export default ___CSS_LOADER_EXPORT___;
