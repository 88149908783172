// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topics_topics__HdTKW {
  margin: 40px 0 0;
  overflow: hidden;
  height: calc(100vh - 116px);
  display: grid;
  grid-template: repeat(2, auto)/21% auto 21%;
}
.topics_topics__center__IPJJ6 {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  gap: 32px;
}`, "",{"version":3,"sources":["webpack://./src/components/main/Topics/topics.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;EACA,2BAAA;EACA,aAAA;EACA,2CAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;AACR","sourcesContent":[".topics {\r\n    margin: 40px 0 0;\r\n    overflow: hidden;\r\n    height: calc(100vh - 116px);\r\n    display: grid;\r\n    grid-template: repeat(2, auto) / 21% auto 21%;\r\n\r\n    &__center {\r\n        display: flex;\r\n        flex-direction: column;\r\n        overflow-y: hidden;\r\n        gap: 32px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topics": `topics_topics__HdTKW`,
	"topics__center": `topics_topics__center__IPJJ6`
};
export default ___CSS_LOADER_EXPORT___;
