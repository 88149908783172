import React from 'react'

export default function ComingSoon() {
    return (
        <h1 style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 283.4px)',
            fontSize: '4em'
        }}>Coming soon</h1>
    )
}
