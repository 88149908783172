// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asideMessageInput_messageInput__o1r1A {
  margin: 30px 0;
}
.asideMessageInput_messageInput__wrapper__VxFoX {
  display: flex;
  justify-content: center;
}
.asideMessageInput_messageInput__input__mpecx {
  display: block;
  background-color: white;
  width: 73%;
  border: none;
  border-radius: 12px 0 0 12px;
  padding: 16px 20px;
  font-family: "Poppins";
  font-size: 0.875em;
  color: #0C0C0C;
  outline: none;
}
.asideMessageInput_messageInput__input__mpecx::placeholder {
  color: rgba(28, 28, 28, 0.2);
  line-height: 142.857%;
}
.asideMessageInput_messageInput__button__enx5S {
  background-color: white;
  border-radius: 0 12px 12px 0;
  border: none;
  width: 16%;
  cursor: pointer;
  transition: all 0.3s;
}
.asideMessageInput_messageInput__button__enx5S:hover {
  background-color: #c8c5c5;
}`, "",{"version":3,"sources":["webpack://./src/components/main/Topics/AsideChat/AsideMessageInput/asideMessageInput.module.scss"],"names":[],"mappings":"AAKA;EACI,cAAA;AAJJ;AAMI;EACI,aAAA;EACA,uBAAA;AAJR;AAOI;EACI,cAAA;EACA,uBAfG;EAgBH,UAAA;EACA,YAAA;EACA,4BAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;EACA,cArBK;EAsBL,aAAA;AALR;AAOQ;EACI,4BAxBQ;EAyBR,qBAAA;AALZ;AASI;EACI,uBAhCG;EAiCH,4BAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;EACA,oBAAA;AAPR;AASQ;EACI,yBArCK;AA8BjB","sourcesContent":["$bg-color: white;\r\n$text-color: #0C0C0C;\r\n$placeholder-color: rgba(28, 28, 28, 0.20);\r\n$hover-bg-color: #c8c5c5;\r\n\r\n.messageInput {\r\n    margin: 30px 0;\r\n    \r\n    &__wrapper {\r\n        display: flex;\r\n        justify-content: center;\r\n    }\r\n\r\n    &__input {\r\n        display: block;\r\n        background-color: $bg-color;\r\n        width: 73%;\r\n        border: none;\r\n        border-radius: 12px 0 0 12px;\r\n        padding: 16px 20px;\r\n        font-family: 'Poppins';\r\n        font-size: 0.875em;\r\n        color: $text-color;\r\n        outline: none;\r\n\r\n        &::placeholder {\r\n            color: $placeholder-color;\r\n            line-height: 142.857%;\r\n        }\r\n    }\r\n\r\n    &__button {\r\n        background-color: $bg-color;\r\n        border-radius: 0 12px 12px 0;\r\n        border: none;\r\n        width: 16%;\r\n        cursor: pointer;\r\n        transition: all .3s;\r\n\r\n        &:hover {\r\n            background-color: $hover-bg-color;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageInput": `asideMessageInput_messageInput__o1r1A`,
	"messageInput__wrapper": `asideMessageInput_messageInput__wrapper__VxFoX`,
	"messageInput__input": `asideMessageInput_messageInput__input__mpecx`,
	"messageInput__button": `asideMessageInput_messageInput__button__enx5S`
};
export default ___CSS_LOADER_EXPORT___;
