// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes loader_loading_animation__fDUiP {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.loader_loading_frame__cLilP {
  width: 20px;
  height: 20px;
  overflow: hidden;
}

.loader_loading__5YOL4 {
  width: 100%;
  height: 100%;
  position: relative;
}
.loader_loading__5YOL4 div {
  box-sizing: content-box;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 8px;
  height: 8px;
  border: 4px solid #000000;
  border-top-color: transparent;
  border-radius: 50%;
  animation: loader_loading_animation__fDUiP 1s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/components/main/Chat/ChatWindow/Loader/loader.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,6CAAA;EACF;EAEA;IACE,+CAAA;EAAF;AACF;AAGA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AADF;AAGE;EACE,uBAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,WAAA;EACA,yBAAA;EACA,6BAAA;EACA,kBAAA;EACA,6DAAA;AADJ","sourcesContent":["@keyframes loading_animation {\n  0% {\n    transform: translate(-50%, -50%) rotate(0deg);\n  }\n\n  100% {\n    transform: translate(-50%, -50%) rotate(360deg);\n  }\n}\n\n.loading_frame {\n  width: 20px;\n  height: 20px;\n  overflow: hidden;\n}\n\n.loading {\n  width: 100%;\n  height: 100%;\n  position: relative;\n\n  div {\n    box-sizing: content-box;\n    position: absolute;\n    top: 10px;\n    left: 10px;\n    width: 8px;\n    height: 8px;\n    border: 4px solid #000000;\n    border-top-color: transparent;\n    border-radius: 50%;\n    animation: loading_animation 1s linear infinite;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading_frame": `loader_loading_frame__cLilP`,
	"loading": `loader_loading__5YOL4`,
	"loading_animation": `loader_loading_animation__fDUiP`
};
export default ___CSS_LOADER_EXPORT___;
