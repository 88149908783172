// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.medias_medias__ml6yI ul {
  display: flex;
  gap: 16px;
}

@media screen and (max-width: 991.98px) {
  .medias_medias__ml6yI ul {
    margin-right: 20px;
    gap: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/shared/Medias/medias.module.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,SAAA;AAAR;;AAIA;EAEQ;IACI,kBAAA;IACA,SAAA;EAFV;AACF","sourcesContent":[".medias {\n    ul {\n        display: flex;\n        gap: 16px;\n    }\n}\n\n@media screen and (max-width: 991.98px) {\n    .medias {\n        ul {\n            margin-right: 20px;\n            gap: 12px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"medias": `medias_medias__ml6yI`
};
export default ___CSS_LOADER_EXPORT___;
