// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asideButton_asideButton__ZJ90l {
  height: 100px;
  width: 15px;
  background-color: #0C0C0C;
  border-radius: 8px 0 0 8px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 50px);
  right: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/main/Topics/AsideChat/AsideButton/asideButton.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,WAAA;EACA,yBALO;EAMP,0BAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,qBAAA;EACA,WAAA;AADJ","sourcesContent":["$bg-color: #0C0C0C;\r\n\r\n.asideButton{\r\n    height: 100px;\r\n    width: 15px;\r\n    background-color: $bg-color;\r\n    border-radius: 8px 0 0 8px;\r\n    border: none;\r\n    cursor: pointer;\r\n    position: absolute;\r\n    top: calc(50% - 50px);\r\n    right: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"asideButton": `asideButton_asideButton__ZJ90l`
};
export default ___CSS_LOADER_EXPORT___;
