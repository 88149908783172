// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPage_mainPage__wrapper__GUSqS {
  padding: 0 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/main/MainPage/mainPage.module.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR","sourcesContent":[".mainPage{\n    &__wrapper{\n        padding: 0 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainPage__wrapper": `mainPage_mainPage__wrapper__GUSqS`
};
export default ___CSS_LOADER_EXPORT___;
