// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messageInput_messageInput__zAOU8 {
  margin: 30px 0;
}
.messageInput_messageInput__wrapper__\\+SVcD {
  display: flex;
  justify-content: center;
  padding: 0 12px 0 0;
}
.messageInput_messageInput__input__CVILo {
  display: block;
  background-color: #F8F8F8;
  width: 73%;
  border: none;
  border-radius: 12px 0 0 12px;
  padding: 16px 20px;
  font-family: "Poppins";
  font-size: 0.875em;
  color: #0C0C0C;
  outline: none;
}
.messageInput_messageInput__input__CVILo::placeholder {
  color: rgba(28, 28, 28, 0.2);
  line-height: 142.857%;
}
.messageInput_messageInput__button__75Omf {
  background-color: #F8F8F8;
  border-radius: 0 12px 12px 0;
  border: none;
  width: 4%;
  cursor: pointer;
  transition: all 0.3s;
}
.messageInput_messageInput__button__75Omf:hover {
  background-color: #c8c5c5;
}`, "",{"version":3,"sources":["webpack://./src/components/main/Chat/MessageInput/messageInput.module.scss"],"names":[],"mappings":"AAKA;EACI,cAAA;AAJJ;AAMI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AAJR;AAOI;EACI,cAAA;EACA,yBAhBG;EAiBH,UAAA;EACA,YAAA;EACA,4BAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;EACA,cAtBK;EAuBL,aAAA;AALR;AAOQ;EACI,4BAzBQ;EA0BR,qBAAA;AALZ;AASI;EACI,yBAjCG;EAkCH,4BAAA;EACA,YAAA;EACA,SAAA;EACA,eAAA;EACA,oBAAA;AAPR;AASQ;EACI,yBAtCK;AA+BjB","sourcesContent":["$bg-color: #F8F8F8;\r\n$text-color: #0C0C0C;\r\n$placeholder-color: rgba(28, 28, 28, 0.20);\r\n$hover-bg-color: #c8c5c5;\r\n\r\n.messageInput {\r\n    margin: 30px 0;\r\n    \r\n    &__wrapper {\r\n        display: flex;\r\n        justify-content: center;\r\n        padding: 0 12px 0 0;\r\n    }\r\n\r\n    &__input {\r\n        display: block;\r\n        background-color: $bg-color;\r\n        width: 73%;\r\n        border: none;\r\n        border-radius: 12px 0 0 12px;\r\n        padding: 16px 20px;\r\n        font-family: 'Poppins';\r\n        font-size: 0.875em;\r\n        color: $text-color;\r\n        outline: none;\r\n\r\n        &::placeholder {\r\n            color: $placeholder-color;\r\n            line-height: 142.857%;\r\n        }\r\n    }\r\n\r\n    &__button {\r\n        background-color: $bg-color;\r\n        border-radius: 0 12px 12px 0;\r\n        border: none;\r\n        width: 4%;\r\n        cursor: pointer;\r\n        transition: all .3s;\r\n\r\n        &:hover {\r\n            background-color: $hover-bg-color;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageInput": `messageInput_messageInput__zAOU8`,
	"messageInput__wrapper": `messageInput_messageInput__wrapper__+SVcD`,
	"messageInput__input": `messageInput_messageInput__input__CVILo`,
	"messageInput__button": `messageInput_messageInput__button__75Omf`
};
export default ___CSS_LOADER_EXPORT___;
