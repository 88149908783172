// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coursePath_topics__coursePath__G6L6X {
  grid-column: 2;
  grid-row: 1/2;
}
.coursePath_topics__coursePath__wrapper__adyZL {
  width: 90%;
  margin: 0 auto;
}
.coursePath_topics__coursePath__nonActive__c8haY {
  font-family: "Poppins";
  color: #686868;
  font-size: 1em;
  line-height: 150%;
}
.coursePath_topics__coursePath__slash__2BjcU {
  margin: 0 5px;
}
.coursePath_topics__coursePath__G6L6X a {
  text-decoration: none;
}
.coursePath_topics__coursePath__active__bFa\\+f {
  color: #0C0C0C;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/main/Topics/CoursePath/coursePath.module.scss"],"names":[],"mappings":"AAGA;EACI,cAAA;EACA,aAAA;AAFJ;AAII;EACI,UAAA;EACA,cAAA;AAFR;AAKI;EACI,sBAAA;EACA,cAdK;EAeL,cAAA;EACA,iBAAA;AAHR;AAMI;EACI,aAAA;AAJR;AAOI;EACI,qBAAA;AALR;AAQI;EACI,cA3BY;EA4BZ,gBAAA;AANR","sourcesContent":["$text-color: #686868;\n$active-text-color: #0C0C0C;\n\n.topics__coursePath {\n    grid-column: 2;\n    grid-row: 1 / 2;\n\n    &__wrapper{\n        width: 90%;\n        margin: 0 auto;\n    }\n\n    &__nonActive {\n        font-family: 'Poppins';\n        color: $text-color;\n        font-size: 1em;\n        line-height: 150%;\n    }\n\n    &__slash {\n        margin: 0 5px;\n    }\n\n    a{\n        text-decoration: none;\n    }\n\n    &__active {\n        color: $active-text-color;\n        font-weight: 600;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topics__coursePath": `coursePath_topics__coursePath__G6L6X`,
	"topics__coursePath__wrapper": `coursePath_topics__coursePath__wrapper__adyZL`,
	"topics__coursePath__nonActive": `coursePath_topics__coursePath__nonActive__c8haY`,
	"topics__coursePath__slash": `coursePath_topics__coursePath__slash__2BjcU`,
	"topics__coursePath__active": `coursePath_topics__coursePath__active__bFa+f`
};
export default ___CSS_LOADER_EXPORT___;
